.sidebar-wrapper {
    margin: 0px 16px;
    top: 20px;
    position: absolute;
}

.sidebar-container {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #ffffff;
    color: #922c87;
    display: grid;
    align-items: center;
    left: 0;
    transition: 0.3s ease-in-out;
}

.opacity-on {
    opacity: 1;
    top: 62px
}

.opacity-off {
    opacity: 0;
    top: -100%;
}

.icon {
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
    position: absolute;
    top: 2rem;
    right: 2.5rem;
}

.close-icon {
    color: whitesmoke
}

.sidebar-menu {
    display: flex;
    text-align: center;
    margin-bottom: 4rem;
    flex-direction: column;
    align-items: flex-start;
}

.sidebar-links {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    color: #922c87;
}

@media screen and (max-width: 480px) {
    .sidebar-menu {
        grid-template-rows: repeat(4, 60px)
    }
}

.button-wrap {
    display: flex;
    justify-content: center;
}