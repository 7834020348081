.icon-hover i {
    background-color: white;
    color: black;
    transition: all 0.3s ease;
  }
  
  .icon-hover:hover i {
    background-color: #922c87;
    /* Set your desired background color */
    color: white;
    /* Change the icon color to white */
  }
  .icon-hover i {
    background-color: white;
    color: black;
    transition: all 0.3s ease;
  }

  