.fixed {
  position: fixed;
}

header {
  top: 0px;
  left: 0;
  height: 60px;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  width: 100vw;
  background: #FDFEFE;
  margin-bottom: 10px;
  margin: 0;
}


.link {
  text-decoration: none;
  display: flex;
  font-size: 1.125rem;
  font-weight: 600;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}

.link::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background: #d946ef;
  transform: scaleX(0);
  transition: transform 0.3s
}

/* .link:hover::after {
  transform: scaleX(1);
} */

.mobile-menu-icon {
  width: 90px;
  font-size: large;
}

.icons {
  display: flex;
  align-items: center;
}

.icon-tabler {
  margin-right: 5px;
}

.menu-items {
  display: flex;
  align-items: center;
  color: #922c87;
  margin: 1rem 2rem;

}

/* .link:hover {
  color: #d946ef;
} */

.icons {
  display: none;
}

@media screen and (max-width: 768px) {
  .menu-items {
    display: none;
  }

  .icons {
    /* display: block; */
    color: white;
    display: flex;
    align-items: center;
    font-size: 4vh;
    cursor: pointer;
    top: 0;
    right: 0;
  }
}