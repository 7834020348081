#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Black Han Sans", Sans-serif;
  color: var(--text-color);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow: auto;
  direction: ltr;
  background: #e79aff;
}