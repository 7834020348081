@tailwind base;
@tailwind components;
@tailwind utilities;

.content {
  top: 52px;
  position: fixed;
  overflow: auto;
  padding: 1rem 2rem;
  width: 100vw;
  height: 100vh;
}

.footer-content {
  background: #FC0FC0;
  color: #FDFEFE;
  padding: 1rem 2rem;
  margin: 4rem 0rem;
}

.Hero-bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.4;
  mix-blend-mode: multiply;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.bg-color {
  background: #818cf8;
}

.p-button.p-button-link:not(:disabled):focus {
  box-shadow: none !important;
}

.p-treenode-children {
  list-style: none;
  margin: 0;
  padding: 0 25px;
}

@media (15em <=width <=50em) {
  .content {
    padding: 0rem;
    padding-bottom: 20px;
  }
}